import React from 'react';
import {Link} from 'gatsby';
import Button from './Button';

const BannerSimple = ({title, description}) => {
  return (
    <section className="mx-5 py-28">
      <div className="flex flex-col md:flex-row w-full lg:max-w-3xl lg:mx-auto mb-8 bg-[#F8F9FC] md:bg-cover rounded-[30px] filter drop-shadow-2xl items-center justify-center pt-12 pb-8 text-blue">
        <div className="flex-col items-center justify-center mt-4 md:mt-0">
          <h3 className="pb-4 text-3xl font-bold text-center md:text-2xl ">
            <span className="text-transparent bg-trustle-gradient bg-clip-text">{title}</span>
          </h3>

          <div className="px-4 text-center pb-7 text-md md:text-lg">{description}</div>
          <div className="w-full px-4 mx-auto text-center">
            <Link to="/demo">
              <Button label="Schedule Demo" />
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};
export default BannerSimple;
